<template>
  <div class="product-container">
    <!-- banner_cp -->
    <div class="banner_cp" style="background-color: #f6f6f6">
      <div class="margin width1480 hidden">
        <div class="l">
          <dl style="display: block">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_jg.png"
            />
          </dl>
        </div>
        <div class="r">
          <div class="bg a-fdB">
            <dd>金 刚</dd>
            <dl>
              <p>全球高标工艺 | 极致安全防护 | 个性外观设计</p>
            </dl>
            <ol class="f">
              <dt class="o">
                <span><i style="background-color: #909090"></i></span>
                <p>银色</p>
              </dt>
            </ol>
            <a @click="$U.openProductFile('jingang')">产品手册</a>
          </div>
        </div>
      </div>
    </div>
    <!-- jg_xzw -->
    <div id="g1">
      <div
        class="jg_xzw"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_xzw_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <!-- <dd class="cp_bt_y">性价比之王</dd> -->
            <dl class="cp_bt_d">极致工艺 高能有颜</dl>
            <dd class="cp_bt_y">高颜值呼吸灯 充电状态一目了然</dd>
          </div>
          <div class="f">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_xzw.png"
              class="c"
            />
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_xzw_yy.png"
              class="y"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- jg_jcjc -->
    <div id="g2">
      <div
        class="jg_jcjc"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_jcjc_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <dd class="cp_bt_y">即插即充</dd>
            <dl class="cp_bt_d">蓝牙无感充电</dl>
            <dd class="cp_bt_y">适用任何场景 无WiF/4G也能用</dd>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- cp_aqfh -->
  <div id="g3">
    <div class="margin width1480 hidden">
      <ul class="cp_aqfh">
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh1.png"
              />
            </ol>
            <dd>过压/欠压保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh2.png"
              />
            </ol>
            <dd>过流保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh3.png"
              />
            </ol>
            <dd>雷电保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh4.png"
              />
            </ol>
            <dd>接地保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh5.png"
              />
            </ol>
            <dd>防漏电保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh6.png"
              />
            </ol>
            <dd>短路保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh7.png"
              />
            </ol>
            <dd>环境电源异常数据记录</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh8.png"
              />
            </ol>
            <dd>IP66</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh9.png"
              />
            </ol>
            <dd>过温保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh10.png"
              />
            </ol>
            <dd>预约充电</dd>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- jg_gscd -->
  <div id="g4">
    <div
      class="jg_gscd"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_gscd_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="t">
          <dd>高速充电 科技光效</dd>
          <dl>适用于国内推出的纯电动和插电式油电混合动力汽车</dl>
        </div>
      </div>
    </div>
  </div>
  <!-- jg_znjc -->
  <div id="g5">
    <div class="jg_znjc_tit">
      <dd class="cp_bt_d">智能交互 随心来电</dd>
      <dl class="cp_bt_y">三种充电模式</dl>
    </div>
    <div
      class="jg_znjc"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_znjc_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_znjc.png"
          class="tp"
        />
        <div class="r">
          <dd class="cp_bt_d">支持高海拔工作</dd>
          <dl class="cp_bt_y">
            <p>采用PCB三防喷漆，防尘、防雾、防盐</p>
            <p>外壳防紫外线，持久不变色</p>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <!-- xhx_zyqh -->
  <div id="g6">
    <div
      class="jg_znxp"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_znxp_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="l">
          <dd class="cp_bt_d">智能芯片 安全不伤车</dd>
          <dl class="cp_bt_y">第四代主板芯片，充满断电更安全</dl>
        </div>
      </div>
    </div>
  </div>
  <!-- xhx_aqwd -->
  <div id="g7">
    <!-- <div class="jg_lgcdjk" style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_lgcdjk_bg.jpg);">
    <div class="margin width1480 hidden">
        <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_lgcdjk.png" class="tp" />
        <div class="r">
            <dd class="cp_bt_d">2款充电口选择</dd>
            <dl class="cp_bt_y">7孔镀银枪头，上万次使用不变形</dl>
        </div>
    </div>
</div> -->
  </div>
  <!-- xhx_lxbz -->
  <div id="g8">
    <div
      class="cp_f_bg jingang_f"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_zncx_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="t">
          <dd>智慧出行 与你同行</dd>
          <dl>
            安装网络覆盖全国360个城市,已为100万户家庭生产并安装了家庭充电桩！
          </dl>
          <ol>
            <a  @click="$U.openProductFile('jingang')">产品手册<i class="fa fa-angle-right"></i></a>
          </ol>
        </div>
        <!--<div class="f">
            <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/jg_zncx.png" />
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from 'vue'
export default {
  name: 'jingang',
  components: {},
  setup() {
    const {proxy} = getCurrentInstance()
    onMounted(() => {
      document.title = '金刚'
      
      // 滚动到页面最上方
      window.scrollTo(0, 0);
      
      $('.banner_cp .width1480 .r ol.f dt').click(function () {
        var liindex = $('.banner_cp .width1480 .r ol.f dt').index(this)
        $(this).addClass('o').siblings().removeClass('o')
        $('.banner_cp .width1480 .l dl')
          .eq(liindex)
          .fadeIn(150)
          .siblings('dl')
          .hide()
      })

      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop() //滚动条距离顶端值
        for (var i = 1; i < 20; i++) {
          if ($('#g' + i) && $('#g' + i).offset()) {
          //加循环
          if ($('#g' + i).offset().top <= wst) {
            //判断滚动条位置
            $('#g' + i).addClass('on') //给当前导航加c类
          } else {
            //$("#p"+i).removeClass('on');
          }
        }
        }
      })
    })
    return {}
  },
}
</script>

<style lang="less" scoped></style>
